import { SQLiteDBConnection } from '@capacitor-community/sqlite'
import { closeDatabaseAndConnection, initializeDatabase } from '../sqlite'
import { base64ToBlob, blobToBase64 } from '@/utils/base64Toblob'
import { entityType as TEntity } from '@/db/tables'

export type QueuePhotoType =
  | 'PROGRESS_PICTURES'
  | 'TASK_BEFORE'
  | 'TASK_LIST'
  | 'TASK'
  | 'TASK_PICTURES'
  | 'FINAL_PICTURES'
  | 'PROFILE_PICTURES'
  | 'TASK_AFTER'
  | 'PROBLEM'
  | 'PREPRODUCTION_PICTURES'

export interface IQueuePhoto {
  id: string
  projectId?: string
  entityName?: string
  latitude: string
  longitude: string
  photoType: QueuePhotoType
  base64?: string
  base64Content?: string
  photoTypeId?: string
  uploadPhotoType?: uploadPhotoType
  annotation?: string
  base64_annotation?: string
  file?: Blob
  type_media?: 'IMAGE' | 'VIDEO'
  entityType?: TEntity
}

export type QueueStatusType = 'IDLE' | 'UPLOADING' | 'COMPLETE' | 'ERROR'

export type uploadPhotoType = 'UNKNOWN' | 'CAMERA_MOVIL' | 'CAMERA_DESKTOP' | 'LIBRARY_MOVIL' | 'LIBRARY_DESKTOP'

export type entityType = 'Lead' | 'Project'

export interface newIQueuePhoto {
  id: string
  projectId?: string
  entityName?: string
  poNumberProject?: string
  latitude: string
  longitude: string
  photoType?: QueuePhotoType
  entityType?: entityType
  photoTypeId?: string
  date: Date
  file: Blob
  status?: QueueStatusType
  uploadDate?: Date
  errorMessage?: string
  retries: number
  uploadPhotoType?: uploadPhotoType
  annotation?: string
  base64_annotation?: string
}

export interface newIQueuePhotoBD extends Omit<newIQueuePhoto, 'date' | 'file' | 'uploadDate'> {
  date: string
  file: string
  uploadDate: string
}

export const QUEUE_PHOTO_TABLE = 'id, entityName, projectId, latitude, longitude, base64, photoType, photoTypeId'

export const insertDataIntoTableIQueuePhoto = async (db: SQLiteDBConnection, photo: IQueuePhoto) => {
  const queryInsert = `
INSERT INTO QUEUE_PHOTO_TABLE VALUES (?,?,?,?,?,?,?,?,?) `
  const values = [photo.id, photo.entityName, photo.projectId, photo.latitude, photo.longitude, photo.base64, photo.base64Content, photo.photoType, photo.photoTypeId]
  await db.query(queryInsert, values)
}

export const updateDataIntoTableIQueuePhoto = async (db: SQLiteDBConnection, photo: IQueuePhoto) => {
  const queryUpdate = `
    UPDATE QUEUE_PHOTO_TABLE SET projectId = ?, latitude = ?, longitude = ?, base64 = ?, base64Content = ?, photoType = ?, photoTypeId = ? WHERE id = ?`
  const values = [photo.projectId, photo.latitude, photo.longitude, photo.base64, photo.base64Content, photo.photoType, photo.photoTypeId, photo.id]

  await db.query(queryUpdate, values)
}

export const deleteDataIntoTableIQueuePhoto = async (db: SQLiteDBConnection, id: string) => {
  const queryDelete = `DELETE FROM QUEUE_PHOTO_TABLE WHERE id = ?`
  const values = [id]

  await db.query(queryDelete, values)
}

export const getDataFromTableIQueuePhotos = async (db: SQLiteDBConnection): Promise<IQueuePhoto[]> => {
  const querySelect = `SELECT * FROM QUEUE_PHOTO_TABLE`
  const resSelect = await db.query(querySelect)
  return resSelect.values ?? []
}

export const addIQueuePhoto = async (photo: IQueuePhoto) => {
  try {
    const { db, sqlite } = await initializeDatabase()
    await insertDataIntoTableIQueuePhoto(db, photo)
    await closeDatabaseAndConnection(db, sqlite)
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const getIQueuePhotoById = async (id: string) => {
  try {
    const { db, sqlite } = await initializeDatabase()
    const querySelect = `SELECT * FROM QUEUE_PHOTO_TABLE WHERE id = ?`
    const resSelect = await db.query(querySelect, [id])
    const data = resSelect.values ?? []
    await closeDatabaseAndConnection(db, sqlite)
    if (data.length === 0) return null
    return data[0] as IQueuePhoto
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const addIQueuePhotos = async (photos: IQueuePhoto[]) => {
  try {
    const { db, sqlite } = await initializeDatabase()
    for await (const photo of photos) {
      await insertDataIntoTableIQueuePhoto(db, photo)
    }
    await closeDatabaseAndConnection(db, sqlite)
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const deleteIQueuePhoto = async (id: string) => {
  try {
    const { db, sqlite } = await initializeDatabase()
    await deleteDataIntoTableIQueuePhoto(db, id)
    await closeDatabaseAndConnection(db, sqlite)
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const updateIQueuePhoto = async (photo: IQueuePhoto) => {
  try {
    const { db, sqlite } = await initializeDatabase()
    await updateDataIntoTableIQueuePhoto(db, photo)
    await closeDatabaseAndConnection(db, sqlite)
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const getIQueuePhotos = async (): Promise<IQueuePhoto[]> => {
  try {
    const { db, sqlite } = await initializeDatabase()
    const data = await getDataFromTableIQueuePhotos(db)
    await closeDatabaseAndConnection(db, sqlite)
    return data
  } catch (error) {
    console.error(error)
    throw error
  }
}

// ------------------------------ newIQueuePhoto ------------------------------

export const createNewTableIQueuePhoto = async (db: SQLiteDBConnection) => {
  const createTableSQL = `
  CREATE TABLE IF NOT EXISTS NEW_QUEUE_PHOTO_TABLEV2 (
  id TEXT PRIMARY KEY,
  entityName TEXT,
  projectId TEXT,
  poNumberProject TEXT,
  latitude TEXT NOT NULL,
  longitude TEXT NOT NULL,
  photoType TEXT NOT NULL,
  photoTypeId TEXT,
  date DATETIME NOT NULL,
  file BLOB NOT NULL,
  status TEXT NOT NULL,
  uploadDate DATETIME,
  errorMessage TEXT,
  retries INTEGER NOT NULL,
  uploadPhotoType TEXT NOT NULL,
  entityType TEXT NOT NULL
)
`
  await db.execute(createTableSQL)
  // console.log(`res: ${JSON.stringify(res)}`)
}

export const checkIsExistTableNewIQueuePhoto = async (db: SQLiteDBConnection) => {
  const queryCheck = `SELECT name FROM sqlite_master WHERE type='table' AND name='NEW_QUEUE_PHOTO_TABLEV2';`
  const res = await db.query(queryCheck)
  return (res.values ?? []).length > 0
}

export const insertDataIntoTableNewIQueuePhoto = async (db: SQLiteDBConnection, photo: newIQueuePhoto) => {
  try {
    const base64 = await blobToBase64(photo.file)
    const values = [
      photo.id, // 1
      photo.entityName ?? '',
      photo.projectId ?? '', // 2
      photo.poNumberProject ?? '', // 3
      photo.latitude, // 4
      photo.longitude, // 5
      photo.photoType, // 6
      photo.photoTypeId, // 7
      photo.date.toISOString(), // 8
      `data:image/jpeg;base64,${base64}`, // 9
      photo.status, // 10
      photo.date.toISOString(), // 11
      photo.errorMessage ?? '', // 12
      photo.retries ?? 0, // 13
      photo.uploadPhotoType ?? 'UNKNOWN', // 14
      photo.entityType ?? 'Project',
    ]
    console.log('values: ', values)
    const queryInsert = `INSERT INTO NEW_QUEUE_PHOTO_TABLEV2 VALUES (${values.map(() => '?').join(',')})`
    await db.query(queryInsert, values)
  } catch (error) {
    console.log(error)
  }
}

export const updateDataIntoTableNewIQueuePhoto = async (db: SQLiteDBConnection, photo: newIQueuePhoto) => {
  try {
    const base64 = await blobToBase64(photo.file)
    const values = [
      photo.projectId ?? '',
      photo.entityName ?? '',
      photo.poNumberProject ?? '',
      photo.latitude,
      photo.longitude,
      photo.photoType,
      photo.photoTypeId,
      photo.date.toISOString(),
      `data:image/jpeg;base64,${base64}`,
      photo.status ?? '',
      photo.uploadDate?.toISOString() ?? photo.date.toISOString(),
      photo.errorMessage ?? '',
      photo.retries ?? 0,
      photo.uploadPhotoType ?? 'UNKNOWN',
      photo.entityType ?? 'Project',
      photo.id,
    ]
    const queryUpdate = `
    UPDATE NEW_QUEUE_PHOTO_TABLEV2 SET projectId = ?, poNumberProject = ?, latitude = ?, longitude = ?, photoType = ?, photoTypeId = ?, date = ?, file = ?, status = ?, uploadDate = ?, errorMessage = ?, retries = ?, uploadPhotoType = ?, entityType = ? WHERE id = ?`
    await db.query(queryUpdate, values)
  } catch (error) {
    console.log(error)
  }
}

export const deleteDataIntoTableNewIQueuePhoto = async (db: SQLiteDBConnection, id: string) => {
  try {
    const queryDelete = `DELETE FROM NEW_QUEUE_PHOTO_TABLEV2 WHERE id = ?`
    const values = [id]
    await db.query(queryDelete, values)
  } catch (error) {
    console.log(error)
  }
}

export const getDataFromTableNewIQueuePhotos = async (db: SQLiteDBConnection) => {
  const querySelect = `SELECT * FROM NEW_QUEUE_PHOTO_TABLEV2`
  const resSelect = await db.query(querySelect)
  return (resSelect.values ?? []) as newIQueuePhotoBD[]
}

export const addNewIQueuePhoto = async (photo: newIQueuePhoto) => {
  try {
    const { db, sqlite } = await initializeDatabase()
    await insertDataIntoTableNewIQueuePhoto(db, photo)
    await closeDatabaseAndConnection(db, sqlite)
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const getNewIQueuePhotoById = async (id: string) => {
  try {
    const { db, sqlite } = await initializeDatabase()
    const querySelect = `SELECT * FROM NEW_QUEUE_PHOTO_TABLE WHERE id = ?`
    const resSelect = await db.query(querySelect, [id])
    const data = resSelect.values ?? []
    await closeDatabaseAndConnection(db, sqlite)
    if (data.length === 0) {
      return null
    }
    return data[0] as newIQueuePhoto
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const deleteNewIQueuePhoto = async (id: string) => {
  try {
    const { db, sqlite } = await initializeDatabase()
    await deleteDataIntoTableNewIQueuePhoto(db, id)
    await closeDatabaseAndConnection(db, sqlite)
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const updateNewIQueuePhoto = async (photo: newIQueuePhoto) => {
  try {
    const { db, sqlite } = await initializeDatabase()
    await updateDataIntoTableNewIQueuePhoto(db, photo)
    await closeDatabaseAndConnection(db, sqlite)
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const addNewIQueuePhotosCamera = async (photos: newIQueuePhoto[]) => {
  try {
    const { db } = await initializeDatabase()
    for await (const photo of photos) {
      await insertDataIntoTableNewIQueuePhoto(db, photo)
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const addNewIQueuePhotos = async (photos: newIQueuePhoto[]) => {
  try {
    const { db, sqlite } = await initializeDatabase()
    for await (const photo of photos) {
      await insertDataIntoTableNewIQueuePhoto(db, photo)
    }
    await closeDatabaseAndConnection(db, sqlite)
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const getNewIQueuePhotos = async (): Promise<newIQueuePhoto[]> => {
  try {
    const { db, sqlite } = await initializeDatabase()
    const data = (await getDataFromTableNewIQueuePhotos(db)).map((item) => {
      const blob = base64ToBlob(`${item.file}`, 'image/jpeg')
      return {
        ...item,
        file: blob,
        date: new Date(item.date),
        uploadDate: new Date(item?.uploadDate ?? item.date),
      }
    })
    await closeDatabaseAndConnection(db, sqlite)
    return data
  } catch (error) {
    console.error(error)
    throw error
  }
}
